import { useOptionsQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';

export default function useEventsActivityTemplatesOptions({ templateType }) {
  return useOptionsQuery({
    name: 'events/activity_templates',
    params: { templateType },
    request: EventsRoutes.ActivityTemplates.listOptionsRequest,
  });
}
