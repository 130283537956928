import { useQuery } from '@tanstack/react-query';
import { CreativesRoutes } from '@pages/routes';
import { useOptionsQuery } from '@/hooks';

const LIST_QUERY_NAME_KEY = 'creatives/images';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function creativesImagesQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await CreativesRoutes.Images.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export default function useImagesOptions() {
  return useOptionsQuery({
    name: 'creatives/images',
    request: CreativesRoutes.Images.listOptionsRequest,
  });
}

export function useImagesQuery(query = {}) {
  return useQuery(creativesImagesQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
