import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';

export default function List({ response }) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'externalId',
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Data Type',
        accessor: 'dataType',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No exposed attributes yet." />;
  }

  return (
    <Table columns={columns} data={response.items} />
  );
}
