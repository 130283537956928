import React, { useState, useMemo } from 'react';
import { useQuery, useAppData } from '@hooks';

import { useRequireAuth, useRouter, Badge } from '@tripledotstudios/react-core';
import {
  Pagination,
  PageHeader,
  Button,
  Pane,
} from '@components';
import { Table } from '@components/collection';
import LogModal from './LogModal';
import Filters from './Filters';

const ColorStatusMap = { CREATED: 'success', UPDATED: 'warning', DELETED: 'danger' };
const ActionType = ({ value, ...rest }) => (
  <Badge bg={ColorStatusMap[value.toUpperCase()]} {...rest}>{value}</Badge>
);

export default function Index({ routes, showAppFilter }) {
  useRequireAuth();
  const [logId, setLogId] = useState();
  const { query } = useRouter();
  const { response, refetch } = useQuery(routes.indexRequest, query, [query]);
  const { localizedEnums: { 'TDS::RailsCore::Enumerations::ActivityLogActions': ActivityLogActions } } = useAppData();
  const handleClose = () => setLogId(null);

  const columns = useMemo(
    () => {
      const appsById = response?.filters?.applications;
      const cols = [
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Author',
          accessor: 'author',
        },
        {
          Header: 'Entity',
          Cell: ({ row: { original: { ownerId, ownerTypeHuman } } }) => `${ownerTypeHuman} #${ownerId}`,
        },
        {
          Header: 'Action Type',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row: { original: { action } } }) => <ActionType value={ActivityLogActions[action.toUpperCase()]} />,
        },
        {
          Header: 'Created at (UTC)',
          accessor: 'createdAt',
        },
        {
          Header: 'Actions',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row: { original: { id } } }) => <Button.Action onClick={() => setLogId(id)} title="View" />,
        },
      ];
      if (showAppFilter) {
        cols.splice(1, 0, {
          Header: 'Application',
          Cell: ({ row: { original: { applicationId } } }) => appsById && appsById[applicationId],
        });
      }
      return cols;
    },
    [response?.filters],
  );

  return (
    response && (
      <>
        <PageHeader title="Activity Log" />
        <Filters filtersData={response.filters} showAppFilter={showAppFilter} />
        <Pane>
          <Table columns={columns} data={response.items} />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </Pane>
        {logId && <LogModal routes={routes} logId={logId} handleClose={handleClose} />}
      </>
    )
  );
}
