import { PageSection, useConfirm, useRouter } from '@tripledotstudios/react-core';

import { Form } from '@components';
import { useAppData, useQuery } from '@hooks';
import { findById } from '@services/utils';
import {
  FloatingLabeledInput, FloatingLabeledSelect, LabeledInput, RegisteredError,
} from '@components/resource';
import NestedRecordsList from '@components/NestedRecordsList';
import { SegmentationRoutes } from '@pages/routes';

import useObjectiveConfigurationOptions from '../useObjectiveConfigurationOptions';
import { useEventTypes } from '../types/queries';
import ConfigurationObjectives from '../configurations/form/ConfigurationObjectives';
import ActivitiesDurationSummary from '../ActivitiesDurationSummary';

export default function ActivitySetForm({ resource, ...rest }) {
  const { enumOptions } = useAppData();
  const { query } = useRouter();
  const { data: types } = useEventTypes(query);
  const confirm = useConfirm();
  const activityTypesOptions = enumOptions['Events::ActivityTypes'];
  const { isLoading, configurationOptions } = useObjectiveConfigurationOptions();
  const { response: segments } = useQuery(SegmentationRoutes.Segments.indexRequest, { withoutPagination: true });

  if (!types || !segments) return null;

  const typesOptions = types.items.map(({ id, name }) => ({ label: name, value: id }));
  const behaviourOptions = enumOptions['Events::ActivitySetRemainingBehaviours'];
  const isInLive = resource.data.availabilityStateLive;

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch, setValue, getValues }) => {
        const typeId = watch('typeId');
        const type = findById(types, typeId);
        const activitySets = getValues('activitySetIterationsAttributes') || [];

        const resetIterations = () => {
          setValue(
            'activitySetIterationsAttributes',
            activitySets?.map((i) => ({ ...i, _destroy: true, position: -1 })),
          );
        };
        const confirmTypeChange = () => {
          if (!typeId || activitySets.length === 0) {
            return Promise.resolve();
          }

          return confirm.showConfirmation({
            title: 'Are you sure?',
            body: 'Are you sure you want to change the event type?'
              + ' All positions created in this activity set will be cleared.',
          });
        };

        const iterationContent = ({ item }) => !isLoading && (
          <>
            <LabeledInput label="Name" name="name" />
            <RegisteredError name="objectivesError" />
            <ConfigurationObjectives
              maxActivities={type.maxActivities}
              objectiveConfigurationsOptions={configurationOptions}
              objectiveConfigurationTypesOptions={activityTypesOptions}
              segments={segments}
              recurring
            />
            <ActivitiesDurationSummary activities={item.configurationObjectivesAttributes} />
          </>
        );
        const headerContent = ({ item }) => (
          <>
            <b>{`Position ${item.position}`}</b>
            <span className="ms-1">{item.name && `- ${item.name}`}</span>
          </>
        );
        return (
          <>
            <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
              <FloatingLabeledInput label="Name" name="name" />
              <FloatingLabeledSelect
                label="Behaviour for remaining occurrences"
                name="behaviourForRemainingOccurrences"
                options={behaviourOptions}
              />
              <FloatingLabeledSelect
                tooltipText={isInLive ? "Can't change while In-Use" : ''}
                disabled={isInLive}
                onChange={resetIterations}
                onConfirmChange={confirmTypeChange}
                label="Events Type"
                name="typeId"
                options={typesOptions}
              />
            </Form.ControlsLayout>
            <div className="mt-4">
              <PageSection title="Positions">
                <RegisteredError name="iterations" />
                <NestedRecordsList
                  attributesPath="activitySetIterationsAttributes"
                  buildNewItem={() => ({})}
                  entityName="Position"
                  headerContent={headerContent}
                  bodyContent={iterationContent}
                  addDisabled={!typeId}
                  removeDisabled={({ item }) => item.id && isInLive}
                  reorderDisabled={isInLive}
                />
              </PageSection>
            </div>
          </>
        );
      }}
    </Form.Resource>
  );
}
