import React from 'react';
import { Route } from 'react-router-dom';

import { PlayerServerRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';
import Index from './Index';

const Routes = PlayerServerRoutes.ExposedAttributes;

export default function ExposedAttributesRoot() {
  return [
    <Route
      exact
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <>
          <PageTitle text="Exposed Attributes" />
          <Index />
        </>
      )}
    />,
  ];
}
