import { Row, Col } from 'react-bootstrap';
import { useRouter, PageSection } from '@tripledotstudios/react-core';

import {
  Form,
  FormContainer,
  ImageSquare,
  InUse,
} from '@components';
import {
  LabeledCheckbox,
  LabeledInput,
  LabeledSelect,
  RegisteredError,
} from '@components/resource';
import { useQuery } from '@hooks';
import { PaymentsRoutes, LocalisationsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

import GameItems from './GameItems';

const ImagesRoutes = PaymentsRoutes.ProductImages;
const GameItemsRoutes = PaymentsRoutes.GameItems;
const LocalisationKeysRoutes = LocalisationsRoutes.Keys;

export default function GameProductForm({ resource, ...rest }) {
  const { query } = useRouter();
  const { data: defaultValues } = resource;

  const { response: images } = useQuery(ImagesRoutes.indexRequest, { ...query, withoutPagination: true });
  const { response: gameItems } = useQuery(
    GameItemsRoutes.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );
  const { response: localisationKeys } = useQuery(
    LocalisationKeysRoutes.indexRequest, { ...query, deleted: false, withoutPagination: true },
  );

  if (!images?.items || !gameItems?.items || !localisationKeys?.items) {
    return null;
  }

  const imageOptions = [
    { label: 'No Product Image', value: '' },
    ...toOptions(images.items, { transform: (option, { fileUrl }) => Object.assign(option, { fileUrl }) }),
  ];
  const gameItemOptions = toOptions(gameItems.items);
  const localisationKeysOptions = [
    { label: 'No Key', value: '' },
    ...toOptions(localisationKeys.items, {
      transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
        availabilityStateLive,
        availabilityState,
      }),
    }),
  ];
  const imageLabelFormat = ({ label, fileUrl }) => (
    <>
      {label}
      {fileUrl && <ImageSquare className="ms-2" size="30px" src={fileUrl} />}
    </>
  );

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch }) => {
        const imageUrl = images.items.find(({ id }) => id === watch('imageId'))?.fileUrl;

        return (
          <Row>
            <FormContainer>
              <RegisteredError name="base" />
              <LabeledInput label="Name" name="name" />
              <LabeledSelect
                label="Display Name Key"
                name="displayNameKeyId"
                options={localisationKeysOptions}
                formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
              />
              <LabeledCheckbox label="Ad Product" name="adProduct" />

              <LabeledSelect
                label="Image"
                name="imageId"
                options={imageOptions}
                formatOptionLabel={imageLabelFormat}
              />
              {defaultValues.id && <LabeledInput disabled label="Created At (UTC)" name="createdAt" />}
              {defaultValues.id && <LabeledInput disabled label="Updated At (UTC)" name="updatedAt" />}
              <GameItems gameItemOptions={gameItemOptions} permissions={defaultValues.permissions} />
              {defaultValues.id && <InUse.InUseAccordion inUse={defaultValues.inUse} />}
            </FormContainer>
            <Col className="col-xxxl-6 col-xxl-4 col-xl-2 col-lg-12">
              {imageUrl && (
                <PageSection title="Product Image">
                  <ImageSquare size="25rem" src={imageUrl} />
                </PageSection>
              )}
            </Col>
          </Row>
        );
      }}
    </Form.Resource>
  );
}
