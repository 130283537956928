import { Container, Col, Row } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, InUse } from '@/components';
import { FloatingLabeledInput, RegisteredError } from '@/components/resource';
import Rewards from '@/components/events/Rewards';
import DynamicParametersWithTemplate from '@/components/events/DynamicParametersWithTemplate';

import { useAppData, useQuery } from '@/hooks';
import { toOptions } from '@/services/utils';
import { PaymentsRoutes, LocalisationsRoutes } from '@/pages/routes';

import ObjectiveGoals from './form/ObjectiveGoals';

export default function ObjectiveConfigurationsForm(props) {
  const { query } = useRouter();
  const templateTypes = useAppData().enums['Events::ActivityTemplateTypes'];

  const { response: gameItems } = useQuery(
    PaymentsRoutes.GameItems.indexRequest, { ...query, withoutPagination: true, deleted: false },
  );
  const { response: localisationKeys } = useQuery(
    LocalisationsRoutes.Keys.indexRequest, { ...query, deleted: false, withoutPagination: true },
  );
  const gameItemsOptions = gameItems?.items?.map(({ id, name, backupGameItemsData }) => ({
    label: name,
    backupGameItemsData,
    value: id,
  }));
  const localisationKeysOptions = toOptions(localisationKeys?.items || [], {
    transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
      availabilityStateLive,
      availabilityState,
    }),
  });

  return gameItems && localisationKeysOptions && (
    <Form.Resource {...props}>
      <Container fluid className="form-container">
        <Row sm={2} xs={1}>
          <Col>
            <FloatingLabeledInput label="Name" name="name" />
          </Col>
          <Col>
            <InUse.AvailabilityStateFormGroup floating />
          </Col>
        </Row>
        <RegisteredError name="base" />

        <hr />

        <DynamicParametersWithTemplate
          activityTemplateLabel="Objective template"
          templateType={templateTypes.OBJECTIVE}
        />

        {gameItems && (
          <Rewards
            label="Final Rewards"
            formGroupName="rewardsAttributes"
            gameItemsOptions={gameItemsOptions}
          />
        )}
        {gameItems && (
          <ObjectiveGoals
            gameItemsOptions={gameItemsOptions}
            localisationKeysOptions={localisationKeysOptions}
          />
        )}
      </Container>
    </Form.Resource>
  );
}
