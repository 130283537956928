import React from 'react';

import { useAppData } from '@hooks';
import { Form } from '@components';
import { FloatingLabeledInput, FloatingLabeledSelect } from '@components/resource';
import { TemplateParametersForm } from '@components/dynamic_parameters';

export default function ActivityTemplatesForm({ resource, ...rest }) {
  const { inUse } = resource.data;
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_OBJECTIVE];
  const templateTypes = useAppData().enumOptions['Events::ActivityTemplateTypes'];

  return (
    <Form.Resource resource={resource} {...rest}>
      <Form.ControlsLayout>
        <FloatingLabeledInput label="Name" disabled={inUse} name="name" />
        <FloatingLabeledInput label="Key" disabled={inUse} name="key" />
        <FloatingLabeledSelect
          label="Template Type"
          disabled={resource.actionName === 'update'}
          name="templateType"
          options={templateTypes}
        />
      </Form.ControlsLayout>

      <TemplateParametersForm
        inUse={inUse}
        editableInUse
        allowedAssetBundlesTypes={assetBundlesTypes}
      />
    </Form.Resource>
  );
}
