import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { Button, InUse } from '@components';
import { hasApplicationPermissions } from '@services/permissions';

export default function List({
  response, onEditButtonClick, routes, onDelete, onDuplicateButtonClick, selectedResourceId, query,
}) {
  const canCreate = hasApplicationPermissions(['events', 'activity_template', 'write']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Template Type',
        accessor: 'templateType',
      },

      InUse.InUseColumn,
      InUse.InLiveColumn,

      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id,
              name,
              key,
              permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {canCreate && (
              <Button.Duplicate
                className="me-2"
                onClick={() => onDuplicateButtonClick({ id, name, key })}
              />
            )}

            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Events Activity Template "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [JSON.stringify(query)],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No activity templates yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
