import { useFormContext } from 'react-hook-form';
import { IconButton } from '@tripledotstudios/react-core';

export default function ApplyDefaultsButton({ defaults }) {
  const { reset } = useFormContext();

  return (
    <IconButton.Filter
      className="g-start-1 g-col-2 w-fit"
      variant="success"
      type="submit"
      outline
      onClick={() => { reset(defaults); }}
    >
      Apply Default Filters
    </IconButton.Filter>
  );
}
