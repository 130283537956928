import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { PaymentsRoutes, ABTestingRoutes } from '@pages/routes';

import CatalogPresetsTabs from './CatalogPresetsTabs';

export default function ProductsTabs({ defaultValues }) {
  const { query } = useRouter();

  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: productLabelsRaw } = useQuery(
    PaymentsRoutes.ProductLabels.indexRequest,
    { ...query, withoutPagination: true },
  );

  let productLabels = null;

  if (productLabelsRaw) {
    productLabels = productLabelsRaw.items.map(({ name, id }) => ({ label: name, value: id }));
  }

  return experiments && productLabels && (
    <CatalogPresetsTabs
      catalogPresetsAttributes={defaultValues.catalogPresetsAttributes}
      productLabels={productLabels}
      experiments={experiments.items}
    />
  );
}
