import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import { Button } from '@components';
import {
  LabeledInput,
  Label,
  Input,
  RegisteredError,
} from '@components/resource';
import { ParametersForm } from '@components/dynamic_parameters';

export default function BotSets({ max, leaderboardParameters }) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const formGroupName = generateName('botSetsAttributes');
  const sizes = [3, 9];

  const { fields, append, handleDelete } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });
  const appendNewBot = () => append({ minRange: null, maxRange: null });

  let botIndex = 0;

  return (
    <>
      <Label label={<span className="h4">Bot Sets</span>} />
      {fields.map((field, index) => {
        if (field._destroy) return null;

        const itemPath = `${formGroupName}.${index}`;
        botIndex += 1;

        return (
          <FormGroup key={[field.key, index].join('_')} name={itemPath}>
            <PageSection
              title={`Bot Set ${botIndex}`}
              actions={!readOnly && (
                <Button.Delete
                  className="delete me-1"
                  title="Remove"
                  onClick={() => handleDelete(field, index)}
                />
              )}
            >
              <Input hidden name="position" value={botIndex} />
              <RegisteredError name="base" />
              <LabeledInput label="Number of bots" name="botsNumber" type="number" sizes={sizes} />
              <LabeledInput label="Minimum range" name="minRange" type="number" sizes={sizes} />
              <LabeledInput label="Maximum range" name="maxRange" type="number" sizes={sizes} />
              <Label label="Parameters" sizes={sizes}>
                <ParametersForm
                  sourceEntity={leaderboardParameters}
                  templateAttributesName="botsParametersTemplatesAttributes"
                  entityName="bots parameters"
                />
              </Label>
            </PageSection>
          </FormGroup>
        );
      })}
      {!readOnly && (
        <Button.Add className="mt-2" onClick={appendNewBot} disabled={botIndex >= max} title="Add Bot Set" />
      )}
    </>
  );
}
