import React from 'react';
import { Table } from 'react-bootstrap';
import { map, intersection } from 'lodash';
import { PageSection, FormGroup } from '@tripledotstudios/react-core';
import { useFormContext } from 'react-hook-form';

import { Input, Select } from '@components/resource';
import { useFormPermissions, useExtendedFieldArray, useAppData } from '@hooks';
import Button from '@components/buttons';

import DynamicParameters, { Types, TypesWithConfig } from './Inputs';

export default function TemplateParametersForm({
  inUse,
  formGroupName = 'dynamicParameterTemplatesAttributes',
  title = 'Parameters',
  allowedTypes = Types,
  editableInUse = false,
  allowedAssetBundlesTypes,
}) {
  const typeOptions = useAppData().enumOptions.DynamicParameterTemplateTypes.filter(({ value }) => (
    allowedTypes.includes(value)
  ));

  const defaultType = useAppData().enums.DynamicParameterTemplateTypes.BOOLEAN;

  const { watch, setValue } = useFormContext();
  const { fields, append, handleDelete } = useExtendedFieldArray({ name: formGroupName, keyName: 'uiKey' });

  const { readOnly } = useFormPermissions();
  const editable = !readOnly && (editableInUse || !inUse);
  const hasConfigurableTypes = intersection(allowedTypes, TypesWithConfig).length > 0;

  return (
    <PageSection title={title}>
      <Table size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Key</th>
            <th>Type</th>
            {hasConfigurableTypes && <th>Config</th>}
            <th>Default value</th>
            {editable && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {map(fields, (template, index) => {
            const localGroupName = `${formGroupName}.${index}`;
            const typeName = watch(`${localGroupName}.typeName`);
            const handleTypeChange = () => (
              setValue(`${localGroupName}.defaultValue`, DynamicParameters[typeName].defaultValue)
            );
            const fieldDisabled = inUse && template.id;
            const typeDisabled = !!template.id;

            return (!template._destroy && (
              <FormGroup key={`${template.uiKey}.${index}`} name={`${formGroupName}.${index}`}>
                <tr>
                  <td><Input name="name" disabled={!editable} /></td>
                  <td><Input disabled={fieldDisabled} name="key" /></td>
                  <td>
                    <Select disabled={typeDisabled} options={typeOptions} onChange={handleTypeChange} name="typeName" />
                  </td>
                  {hasConfigurableTypes && <td>{DynamicParameters[typeName].config({ disabled: fieldDisabled })}</td>}
                  <td>
                    {DynamicParameters[typeName].input({
                      name: 'defaultValue',
                      formGroupName: localGroupName,
                      allowedAssetBundlesTypes,
                      disabled: fieldDisabled,
                    })}
                  </td>
                  <td>
                    {editable && !fieldDisabled && (
                      <Button.Delete onClick={() => handleDelete(template, index)} title="Remove" />
                    )}
                  </td>
                </tr>
              </FormGroup>
            ));
          })}
        </tbody>
      </Table>
      {editable && (
        <Button.Add title="Add Parameter" size="sm" onClick={() => append({ typeName: defaultType })} />
      )}
    </PageSection>
  );
}
