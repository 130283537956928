import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup, useRouter } from '@tripledotstudios/react-core';

import { ExperimentTabs } from '@components/ab_testing';
import { RegisteredError, LabeledSwitch } from '@components/resource';
import { InUse, Form } from '@components';
import { PaymentsRoutes } from '@pages/routes';

import CatalogSectionsTabs from './CatalogSectionsTabs';

export default function CatalogPresetVariants({
  experiments,
  productLabels,
}) {
  const { query } = useRouter();
  const { getValues } = useFormContext();
  const { generateName } = useFormGroup();

  const catalogPresetInUse = getValues(generateName('inUse'));
  const rootPath = generateName('').slice(0, -1);
  const catalogPresetAttributes = getValues(rootPath);
  const { id: catalogId } = getValues();

  const reloadEntityRequest = () => PaymentsRoutes.Catalogs.editRequest({ id: catalogId, ...query });

  return (
    <>
      <InUse.InUseAccordion inUse={catalogPresetInUse} />
      <RegisteredError name="presetId" />
      <ExperimentTabs
        entityId={catalogPresetAttributes.id}
        entityType="Payments::CatalogPreset"
        experiments={experiments}
        reloadEntityRequest={reloadEntityRequest}
        renderView={({ variantAttributes, defaultVariant }) => (
          <>
            <LabeledSwitch
              name="enabled"
              label="Serve the configuration to players"
              sizes={{
                xxl: [4, 2], xl: [5, 2], lg: [7, 2], md: [10, 2], sm: [10, 2], xs: [10, 2],
              }}
            />
            <Form.When name="enabled" value>
              <CatalogSectionsTabs
                productLabels={productLabels}
                defaultVariantSections={!variantAttributes.isDefaultVariant && defaultVariant.catalogSectionsAttributes}
                catalogSectionsAttributes={variantAttributes.catalogSectionsAttributes}
              />
            </Form.When>
          </>
        )}
      />
    </>
  );
}
