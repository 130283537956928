import React, { useCallback } from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { PlayerServerRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';
import {
  Pagination,
  PageHeader,
  Button,
  Pane,
} from '@components';

import List from './List';

const Routes = PlayerServerRoutes.ExposedAttributes;

export default function ExposedAttributesIndex() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { includeInUse: true });
  const canSynchronize = true;

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeItems = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    <>
      <PageHeader title="Exposed Attributes">
        {canSynchronize && <Button.Synchronize onClick={handleSynchronizeItems} />}
      </PageHeader>
      <Pane>
        <List response={response} />
        <Pagination
          pageCount={response?._meta?.pages}
          refetch={refetch}
        />
      </Pane>
    </>
  );
}
