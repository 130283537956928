import React from 'react';
import { useListQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { Row, Col } from 'react-bootstrap';
import { without } from 'lodash';

import { Form } from '@components';
import {
  Input, LabeledInput, LabeledToggleButtonField,
} from '@components/resource';

const toggleOptions = [
  { label: 'Disabled', value: false, buttonProps: { variant: 'outline-secondary' } },
  { label: 'Enabled', value: true, buttonProps: { variant: 'outline-success' } },
];
const LabeledToggleSwitch = (props) => (
  <LabeledToggleButtonField
    options={toggleOptions}
    type="radio"
    {...props}
  />
);

export default function AudienceGroupFrom({ resource, ...rest }) {
  const { data: typesResponse, isLoading: typesLoading } = useListQuery({ request: EventsRoutes.Types.indexRequest });
  if (typesLoading) return null;

  const types = typesResponse.items;

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ setValue, watch }) => {
        const enabledTypes = watch('eventTypeIds');

        return (
          <Row>
            <Col>
              <Input type="hidden" name="enabled" value="true" />
              <LabeledInput label="Name" name="name" disabled />
              <LabeledInput label="Status" name="status" disabled />
              <LabeledInput label="Group Type" name="groupType" disabled />
              <LabeledInput label="Start at" name="startAt" disabled />
              <LabeledInput label="End at" name="endAt" disabled />
              <hr />
              <LabeledToggleSwitch label="Catalogs" name="shop" />
              <LabeledToggleSwitch label="Campaigns" name="campaigns" />
              <LabeledToggleSwitch label="Piggy Banks" name="piggyBanks" />
              <LabeledToggleSwitch
                label="Events"
                name="events"
                onChange={(value) => { setValue('eventTypeIds', value ? types.map((type) => type.id) : []); }}
              />
              <div className="ms-5">
                {types.map((type) => (
                  <LabeledToggleSwitch
                    label={type.name}
                    name={`eventType_${type.id}`}
                    value={enabledTypes.indexOf(type.id) > -1}
                    onChange={(value) => {
                      setValue('eventTypeIds', value ? [...enabledTypes, type.id] : without(enabledTypes, type.id));
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
        );
      }}
    </Form.Resource>
  );
}
