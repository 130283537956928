import React from 'react';
import { useRouter, PageHeader } from '@tripledotstudios/react-core';

import { Form } from '@components';
import { ButtonsFooter } from '@components/collection';
import { StyledPaneWrapper, StyledPane } from '@components/SplitPane';
import { useQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';
import { TemplateParametersForm } from '@components/dynamic_parameters';

const Routes = EventsRoutes.ClientLeaderboards;

export default function ClientLeaderboardsParametersForm() {
  const { query, push } = useRouter();
  const { response } = useQuery(Routes.editParametersRequest, query);
  const disableSave = !hasApplicationPermissions(['events', 'leaderboard_parameter', 'write']);
  const submitRequest = (values) => Routes.updateParametersRequest({ ...values, ...query });

  if (!response) return null;

  return (
    <>
      <PageHeader title="Events Clients Leaderboards Parameters" />
      <StyledPaneWrapper>
        <StyledPane className="mt-2">
          <Form defaultValues={response}>
            {({ setError }) => {
              const responseHandler = buildResourceResponseHandler({
                actionName: 'Update',
                successMessage: 'Parameters updated successfully',
                setError,
                onSuccess: () => push(Routes.indexPath(query)),
              });
              const onSubmit = (values) => submitRequest(values).then(responseHandler);
              return (
                <Form.Component onSubmit={onSubmit}>
                  <TemplateParametersForm
                    formGroupName="leaderboardParametersTemplatesAttributes"
                    title="Leaderboard Configuration Parameters"
                    inUse={response.inUse}
                    editableInUse
                  />
                  <TemplateParametersForm
                    formGroupName="botsParametersTemplatesAttributes"
                    title="Bots Configuration Parameters"
                    inUse={response.inUse}
                    editableInUse
                  />
                </Form.Component>
              );
            }}
          </Form>
        </StyledPane>
        <ButtonsFooter
          cancelOptions={{ title: 'Back', asLink: true, routes: Routes }}
          submitOptions={{ disabled: disableSave }}
        />
      </StyledPaneWrapper>
    </>
  );
}
