import { Route } from 'react-router-dom';

import { EventsRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';
import Parameters from './Parameters';

const Routes = EventsRoutes.ClientLeaderboards;

export default function ClientLeaderboardsRoot() {
  return [
    ...[Routes.indexRawPath, Routes.editRawPath, Routes.newRawPath].map((path) => (
      <Route
        exact
        key={path}
        path={path}
        element={(
          <>
            <PageTitle text="Events Clients Leaderboards" />
            <Index />
          </>
        )}
      />
    )),
    <Route
      exact
      key={Routes.editParametersRawPath}
      path={Routes.editParametersRawPath}
      element={(
        <>
          <PageTitle entity="Events Clients Leaderboards Parameters" />
          <Parameters />
        </>
      )}
    />,
  ];
}
