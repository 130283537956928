import { useState } from 'react';
import { PageSection } from '@tripledotstudios/react-core';
import { groupBy, sortBy, map } from 'lodash';
import { Tabs, Tab, Alert } from 'react-bootstrap';

import { DiffWrapper } from '@components/resource';
import { useAppData } from '@/hooks';
import { Button } from '@/components';
import CreativeLayout from './CreativeLayout';
import { addIteration, removeIteration, MAX_ITERATIONS_COUNT } from './chained_utils';

import {
  LabeledSwitch,
  SelectWithAddon,
  Label,
  hasError,
} from '@/components/resource';

export default function ChainedLayout({
  availabilityCap,
  creativeLayoutsDataAttributes,
  sequentialOffersOptions,
  template,
  appLinkConfigurations,
  localisationKeys,
  replaceCreativeLayoutData,
}) {
  const { enumOptions } = useAppData();
  const [currentIteration, setCurrentIteration] = useState('0');
  const chainedBehaviourOptions = enumOptions['Campaigns::CreativeChainedBehaviourTypes'];

  const creativeLayoutDataAttributesWithRealIndex = creativeLayoutsDataAttributes.map((attributes, index) => ({
    ...attributes,
    realIndex: index,
  }));
  const { true: offerLayoutAttributes, false: restLayoutAttributes } = groupBy(
    creativeLayoutDataAttributesWithRealIndex.filter(({ _destroy }) => !_destroy),
    ({ layoutType }) => layoutType === 'offer_container',
  );

  const groupedOfferLayoutAttributes = sortBy(groupBy(offerLayoutAttributes, 'iteration'), 0);
  const handleAddIteration = () => {
    const newNumber = addIteration(creativeLayoutsDataAttributes, replaceCreativeLayoutData);
    setCurrentIteration(newNumber);
  };
  const handleIterationRemove = (iterationToRemove) => {
    removeIteration({
      creativeLayoutsDataAttributes,
      groupedOfferLayoutAttributes,
      replaceCreativeLayoutData,
      iterationToRemove,
    });
    setCurrentIteration([iterationToRemove - 1, 0].sort()[1]);
  };
  const deleteAllowed = groupedOfferLayoutAttributes.length > 2;
  const addAllowed = groupedOfferLayoutAttributes.length < MAX_ITERATIONS_COUNT;
  const tabHasError = (attributes) => attributes.some(({ realIndex }) => (
    hasError(`creativeLayoutsDataAttributes.${realIndex}`)
  ));

  return (
    <>
      {restLayoutAttributes && (
        <CreativeLayout
          availabilityCap={availabilityCap}
          creativeLayoutsDataAttributes={restLayoutAttributes}
          layoutDataAttributeName="creativeLayoutsDataAttributes"
          template={template}
          appLinkConfigurations={appLinkConfigurations}
          localisationKeys={localisationKeys}
        />
      )}
      {offerLayoutAttributes?.length > 0 ? (
        <PageSection title="Offers">
          <DiffWrapper for="chainedBehaviour">
            <Label label="Change to next iteration if">
              <SelectWithAddon
                name="chainedBehaviour"
                options={chainedBehaviourOptions}
                after="within duration"
              />
            </Label>
          </DiffWrapper>
          <DiffWrapper for="chainedRotateEnabled">
            <LabeledSwitch
              label="Rotate iterations for this creative"
              name="chainedRotateEnabled"
            />
          </DiffWrapper>

          {addAllowed && (
            <Button.Add
              title="Iteration"
              className="position-relative float-end"
              onClick={() => handleAddIteration()}
            />
          )}
          <Tabs
            className="mb-1"
            transition={false}
            activeKey={currentIteration}
            onSelect={(id) => setCurrentIteration(id)}
          >
            {map(groupedOfferLayoutAttributes, (attributes, iterationIndex) => (
              <Tab
                tabClassName={tabHasError(attributes) ? 'has-errors' : ''}
                eventKey={iterationIndex}
                key={iterationIndex}
                title={`Iteration ${+iterationIndex + 1}`}
              >
                {deleteAllowed && (
                  <Button.DeleteText
                    size="sm"
                    title={`Remove Iteration ${iterationIndex + 1}`}
                    onClick={() => handleIterationRemove(iterationIndex)}
                  />
                )}
                <CreativeLayout
                  availabilityCap={availabilityCap}
                  creativeLayoutsDataAttributes={attributes}
                  sequentialOffersOptions={sequentialOffersOptions}
                  layoutDataAttributeName="creativeLayoutsDataAttributes"
                  template={template}
                  appLinkConfigurations={appLinkConfigurations}
                  localisationKeys={localisationKeys}
                />
              </Tab>
            ))}
          </Tabs>
        </PageSection>
      ) : (
        <Alert variant="info">No offer containers found</Alert>
      )}
    </>
  );
}
