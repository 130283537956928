import React from 'react';
import { PageSection, useFormGroup } from '@tripledotstudios/react-core';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import {
  FloatingLabeledSelect,
  FloatingLabeledDateTimeInput,
  LabeledTimePeriodField,
  DiffWrapper,
  LabeledSwitch,
} from '@components/resource';
import { Form, Button } from '@components';
import { useFormPermissions, useAppData } from '@hooks';

import ConfigurationObjectiveSegments from './ConfigurationObjectiveSegments';

function ParticipationWindowControl() {
  const { generateName } = useFormGroup();
  const { setValue } = useFormContext();

  const handleLimitParticipationWindowChange = (e) => {
    if (!e.target.checked) {
      setValue(generateName('participationWindow'), 0);
    }
  };

  return (
    <>
      <LabeledSwitch
        name="limitParticipationWindow"
        label="Limit participation window"
        onChange={handleLimitParticipationWindowChange}
        sizes={{
          xxl: [3, 4], xl: [4, 4], lg: [4, 4], md: [6, 4], sm: [10, 2], xs: [10, 2],
        }}
      />

      <Form.When name="limitParticipationWindow" value={(e) => e}>
        <Col className="w-100">
          <DiffWrapper for="participationWindow">
            <LabeledTimePeriodField
              sizes={[3, 9]}
              label="Participation window"
              name="participationWindow"
              inputs={['days', 'hours', 'minutes']}
            />
          </DiffWrapper>
        </Col>
      </Form.When>
    </>
  );
}

export default function ConfigurationObjective({
  title,
  handleDelete,
  objectiveConfigurationsOptions,
  objectiveConfigurationTypesOptions,
  recurring,
  isLast,
}) {
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();
  const appData = useAppData();
  const objectiveTimelinesOptions = appData.enumOptions['Events::ConfigurationObjectiveTimelines'];
  const objectiveTimelinesEnum = appData.enums['Events::ConfigurationObjectiveTimelines'];
  const { getValues, setValue, watch } = useFormContext();
  const timeline = watch(generateName('timeline'));

  const updateEndAt = ({ target }) => {
    if (target.value && !getValues(generateName('endAt'))) {
      setValue(generateName('endAt'), target.value);
    }
  };

  return (
    <PageSection
      title={title}
      actions={readOnly || (
        <Button.Delete
          className="delete me-1"
          title="Remove"
          onClick={handleDelete}
        />
      )}
    >
      <Form.ControlsLayout xxl={3} xl={3} lg={3} md={1} sm={1} xs={1} className="mb-1">
        {recurring ? [
          <Col className="w-100">
            <DiffWrapper for="duration">
              <LabeledTimePeriodField
                sizes={[3, 9]}
                label="Duration"
                name="duration"
                inputs={['days', 'hours', 'minutes']}
              />
            </DiffWrapper>
          </Col>,
          !isLast && (
            <Col className="w-100">
              <DiffWrapper for="cooldownBeforeNextActivity">
                <LabeledTimePeriodField
                  sizes={[3, 9]}
                  label="Cooldown before the next activity"
                  name="cooldownBeforeNextActivity"
                  inputs={['days', 'hours', 'minutes']}
                  emptyIfNotSet
                />
              </DiffWrapper>
            </Col>
          ),
        ] : [
          <DiffWrapper for="timeline">
            <FloatingLabeledSelect
              label="Timeline"
              name="timeline"
              options={objectiveTimelinesOptions}
            />
          </DiffWrapper>,
          timeline === objectiveTimelinesEnum.CUSTOM && (
            <DiffWrapper for="startAt">
              <FloatingLabeledDateTimeInput label="Start At" onBlur={updateEndAt} name="startAt" />
            </DiffWrapper>
          ),
          timeline === objectiveTimelinesEnum.CUSTOM && (
          <DiffWrapper for="endAt">
            <FloatingLabeledDateTimeInput label="End At" name="endAt" />
          </DiffWrapper>
          ),
        ]}
      </Form.ControlsLayout>

      <ParticipationWindowControl />

      <ConfigurationObjectiveSegments
        objectiveConfigurationsOptions={objectiveConfigurationsOptions}
        objectiveConfigurationTypesOptions={objectiveConfigurationTypesOptions}
      />
    </PageSection>
  );
}
