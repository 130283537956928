import React, { useState, useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { PageSection, useRouter, useFormGroup } from '@tripledotstudios/react-core';
import { useAppData } from '@hooks';
import { hasApplicationPermissions } from '@services/permissions';

import {
  LabeledInput,
  LabeledTimePeriodField,
  LabeledSwitch,
  LabeledSelect,
  Select,
  RegisteredError,
  DiffWrapper,
  DiffProvider,
} from '@components/resource';
import CreativeTemplateSelect from '@components/creatives/CreativeTemplateSelect';
import { CreativesRoutes } from '@pages/routes';

import ChainedLayoutSupport from './creative_layout/ChainedLayoutSupport';
import CreativeLayout from './creative_layout/CreativeLayout';
import { resetIterations } from './creative_layout/chained_utils';

import CreativeTriggers from './CreativeTriggers';
import PlayerCards from './PlayerCards';

const toBaseSelectOptions = ({
  id, internalName, availabilityState, availabilityStateLive, previewImageUrl, typeNameHumanized,
}) => ({
  label: internalName, value: id, availabilityState, availabilityStateLive, previewImageUrl, typeNameHumanized,
});

export default function CreativeVariant({
  triggers,
  eventsConfigurationsOptions,
  sequentialOffersOptions,
  creativeTemplates,
  appLinkConfigurations,
  localisationKeys,
  defaultVariantAttributes,
  currentAttributes,
}) {
  const { query } = useRouter();
  const [template, setTemplate] = useState();
  const [iconTemplate, setIconTemplate] = useState();
  const [shopCreativeTemplate, setShopCreativeTemplate] = useState();
  const { watch, setValue } = useFormContext();
  const { enums, enumOptions, i18n: { tooltips } } = useAppData();
  const templateTypes = enums['Creatives::TemplateTypes'];
  const iconPositions = enumOptions['Campaigns::CreativeIconLobbyPositions'];
  const contentTypes = enums['Campaigns::CreativeContentTypes'];
  const rawContentTypesOptions = enumOptions['Campaigns::CreativeContentTypes'];
  const contentTypesOptions = hasApplicationPermissions(['campaigns', 'sequential_offer', 'list'])
    ? rawContentTypesOptions
    : rawContentTypesOptions.filter((o) => o.value !== contentTypes.SEQUENTIAL);
  const typesWithReduceAvailability = Object.values(enums['Creatives::LayoutTypesWithReduceAvailability']);

  const [
    creativeIconTemplatesOptions,
    creativeTemplatesOptions,
    shopCreativeTemplatesOptions,
    sequentialOfferTemplatesOptions,
  ] = creativeTemplates.items.reduce((memo, t) => {
    const templateOption = toBaseSelectOptions(t);
    switch (t.typeName) {
      case templateTypes.CREATIVE_ICON:
        return [[...memo[0], templateOption], memo[1], memo[2], memo[3]];
      case templateTypes.OFFER:
      case templateTypes.QUIT_OFFER:
      case templateTypes.GENERIC:
        return [memo[0], [...memo[1], templateOption], memo[2], memo[3]];
      case templateTypes.SHOP_CREATIVE:
        return [memo[0], memo[1], [...memo[2], templateOption], memo[3]];
      case templateTypes.SEQUENTIAL_OFFER:
        return [memo[0], memo[1], memo[2], [...memo[3], templateOption]];
      default:
        // for the rest of the types
        return memo;
    }
  }, [[{ label: 'None', value: '' }], [], [{ label: 'None', value: '' }], []]);

  const { generateName } = useFormGroup();
  const creativeTemplateId = watch(generateName('creativeTemplateId'));
  const creativeIconTemplateId = watch(generateName('creativeIconTemplateId'));
  const shopCreativeTemplateId = watch(generateName('shopCreativeTemplateId'));
  const availabilityCap = watch(generateName('availabilityCap')) || watch(generateName('availabilityCapPerDuration'));
  const creativeEnabled = watch(generateName('enabled'));
  const contentType = watch(generateName('contentType'));

  const duration = watch(generateName('duration'));
  const isSequential = contentType === contentTypes.SEQUENTIAL;

  const { fields: creativeLayoutsDataAttributes, replace: replaceCreativeLayoutData } = useFieldArray({
    name: generateName('creativeLayoutsDataAttributes'),
    keyName: 'key',
  });

  const { fields: shopCreativeLayoutsDataAttributes, replace: replaceShopCreativeLayoutData } = useFieldArray({
    name: generateName('shopCreativeLayoutsDataAttributes'),
    keyName: 'key',
  });

  const resetCreativeLayoutForm = (templateData) => {
    const layoutData = [];

    creativeLayoutsDataAttributes.forEach((data) => data.id && layoutData.push({ ...data, _destroy: true }));
    templateData.layout.forEach(({ type, internalName }) => {
      const reduceOptions = {};
      if (typesWithReduceAvailability.indexOf(type) > -1) {
        reduceOptions.reduceAvailability = availabilityCap > 0;
      }
      layoutData.push({
        internalName, layoutType: type, value: '', iteration: 0, entityId: null, ...reduceOptions,
      });
    });

    replaceCreativeLayoutData(layoutData);
    resetIterations(contentType === contentTypes.CHAINED, layoutData, replaceCreativeLayoutData);
  };

  const resetShopCreativeLayoutForm = (shopCreativeTemplateData) => {
    const layoutData = [];

    shopCreativeLayoutsDataAttributes.forEach((data) => data.id && layoutData.push({ ...data, _destroy: true }));
    shopCreativeTemplateData.layout.forEach(({ type, internalName }) => layoutData.push({
      internalName, layoutType: type, value: '', entityId: null,
    }));

    replaceShopCreativeLayoutData(layoutData);
  };

  useEffect(() => {
    creativeLayoutsDataAttributes.forEach((_attrs, index) => {
      setValue(generateName(`creativeLayoutsDataAttributes.${index}.reduceAvailability`), availabilityCap > 0);
    });
  }, [availabilityCap > 0]);

  useEffect(() => {
    if (!creativeTemplateId) return;

    CreativesRoutes.Templates.editRequest({ ...query, id: creativeTemplateId }).then((res) => {
      setTemplate(res.data);
    });
  }, []);

  useEffect(() => {
    if (creativeIconTemplateId) {
      CreativesRoutes.Templates.editRequest({ ...query, id: creativeIconTemplateId }).then((res) => {
        setIconTemplate(res.data);
      });
    }
    if (shopCreativeTemplateId) {
      CreativesRoutes.Templates.editRequest({ ...query, id: shopCreativeTemplateId }).then((res) => {
        setShopCreativeTemplate(res.data);
      });
    }
  }, []);

  const handleCreativeLayoutChange = (val) => {
    setTemplate(null);

    CreativesRoutes.Templates.editRequest({ ...query, id: val }).then((res) => {
      resetCreativeLayoutForm(res.data);
      setTemplate(res.data);
    });
  };

  const handleCreativeIconChange = (val) => {
    setIconTemplate(null);
    if (!val) return;

    CreativesRoutes.Templates.editRequest({ ...query, id: val }).then((res) => {
      setIconTemplate(res.data);
    });
  };

  const handleShopCreativeChange = (val) => {
    setShopCreativeTemplate(null);

    CreativesRoutes.Templates.editRequest({ ...query, id: val }).then((res) => {
      resetShopCreativeLayoutForm(res.data);
      setShopCreativeTemplate(res.data);
    });
  };

  const handleContentTypeChange = (value) => {
    if (isSequential || value === contentTypes.SEQUENTIAL) {
      // resetting template if previous content type or new is sequential offer
      setTemplate(null);
      setValue(generateName('creativeTemplateId'), null);
    }
    if (value === contentTypes.SEQUENTIAL) {
      setValue(generateName('availabilityCap'), 0);
      setValue(generateName('availabilityCapPerDuration'), 0);
      setValue(generateName('impressionsCap'), 0);
      setValue(generateName('impressionsCapPerDuration'), 0);
    }
    const chained = value === contentTypes.CHAINED;
    resetIterations(chained, creativeLayoutsDataAttributes, replaceCreativeLayoutData);
  };

  const handleDurationChange = (val) => {
    if (val >= 1) return;
    setValue(generateName('availabilityCapPerDuration'), 0);
    setValue(generateName('impressionsCapPerDuration'), 0);
  };

  const contentActions = (
    <DiffWrapper for="contentType" className="d-flex">
      <Select
        label="Type"
        name="contentType"
        onChange={handleContentTypeChange}
        options={contentTypesOptions}
      />
    </DiffWrapper>
  );

  return (
    <DiffProvider baseAttributes={defaultVariantAttributes} currentAttributes={currentAttributes}>
      <DiffWrapper for="enabled">
        <LabeledSwitch
          name="enabled"
          label="Serve the configuration to players"
          sizes={{
            xxl: [5, 4], xl: [6, 4], lg: [9, 3], md: [10, 2], sm: [10, 2], xs: [10, 2],
          }}
        />
      </DiffWrapper>
      {creativeEnabled && (
        <>
          <RegisteredError name="base" />

          <CreativeTriggers triggers={triggers} />

          <PlayerCards eventsConfigurationsOptions={eventsConfigurationsOptions} />

          <PageSection title="Behaviour">
            <DiffWrapper for="impressionsCap">
              <LabeledInput label="Impressions Cap" name="impressionsCap" disabled={isSequential} type="number" />
            </DiffWrapper>
            <DiffWrapper for="availabilityCapPerDuration">
              <LabeledInput
                tooltipText={tooltips.campaigns.configurations.impressions_cap_per_duration.hint}
                label="Impressions Cap per duration"
                name="impressionsCapPerDuration"
                disabled={duration < 1 || isSequential}
                type="number"
              />
            </DiffWrapper>
            <DiffWrapper for="triggerCooldown">
              <LabeledTimePeriodField
                label="Trigger Cooldown"
                name="triggerCooldown"
                inputs={['hours', 'minutes', 'seconds']}
              />
            </DiffWrapper>
            <DiffWrapper for="duration">
              <LabeledTimePeriodField
                onChange={handleDurationChange}
                label="Duration"
                name="duration"
                inputs={['hours', 'minutes', 'seconds']}
              />
            </DiffWrapper>
            <DiffWrapper for="durationCooldown">
              <LabeledTimePeriodField
                label="Duration Cooldown"
                name="durationCooldown"
                inputs={['hours', 'minutes', 'seconds']}
              />
            </DiffWrapper>
            <DiffWrapper for="availabilityCap">
              <LabeledInput label="Availability Cap" name="availabilityCap" disabled={isSequential} type="number" />
            </DiffWrapper>
            <DiffWrapper for="availabilityCapPerDuration">
              <LabeledInput
                tooltipText={tooltips.campaigns.configurations.availability_cap_per_duration.hint}
                label="Availability Cap per duration"
                name="availabilityCapPerDuration"
                disabled={duration < 1 || isSequential}
                type="number"
              />
            </DiffWrapper>
          </PageSection>

          <PageSection title="Content" actions={contentActions}>
            <DiffWrapper for="creativeTemplateId">
              <CreativeTemplateSelect
                label="Creative Template"
                name="creativeTemplateId"
                options={isSequential ? sequentialOfferTemplatesOptions : creativeTemplatesOptions}
                displayType
                template={template}
                menuPlacement="top"
                onChange={handleCreativeLayoutChange}
              />
            </DiffWrapper>
            {template && (
              <ChainedLayoutSupport
                availabilityCap={availabilityCap}
                creativeLayoutsDataAttributes={creativeLayoutsDataAttributes}
                layoutDataAttributeName="creativeLayoutsDataAttributes"
                template={template}
                appLinkConfigurations={appLinkConfigurations}
                sequentialOffersOptions={sequentialOffersOptions}
                localisationKeys={localisationKeys}
                contentType={contentType}
                replaceCreativeLayoutData={replaceCreativeLayoutData}
              />
            )}
          </PageSection>

          <PageSection title="Lasting Creatives">
            <RegisteredError name="lastingCreativesError" />
            <DiffWrapper for="creativeIconTemplateId">
              <CreativeTemplateSelect
                label="Creative Icon Template"
                name="creativeIconTemplateId"
                options={creativeIconTemplatesOptions}
                template={iconTemplate}
                menuPlacement="top"
                onChange={handleCreativeIconChange}
              />
            </DiffWrapper>
            {iconTemplate && (
              <DiffWrapper for="creativeIconLobbyPosition">
                <LabeledSelect
                  label="Creative Icon Lobby Position"
                  name="creativeIconLobbyPosition"
                  options={iconPositions}
                />
              </DiffWrapper>
            )}
            <DiffWrapper for="shopCreativeTemplateId">
              <CreativeTemplateSelect
                label="Shop Creative Template"
                name="shopCreativeTemplateId"
                options={shopCreativeTemplatesOptions}
                template={shopCreativeTemplate}
                menuPlacement="top"
                onChange={handleShopCreativeChange}
              />
            </DiffWrapper>
            {shopCreativeTemplate && (
              <CreativeLayout
                creativeLayoutsDataAttributes={shopCreativeLayoutsDataAttributes}
                layoutDataAttributeName="shopCreativeLayoutsDataAttributes"
                template={shopCreativeTemplate}
                appLinkConfigurations={appLinkConfigurations}
                localisationKeys={localisationKeys}
              />
            )}
          </PageSection>
        </>
      )}
    </DiffProvider>
  );
}
