import React from 'react';
import { Filters } from '@tripledotstudios/react-core/table';

import { useAppData } from '@hooks';
import { CreatedByMeFilter } from '@components/filters';

export default function Filter() {
  const { enumOptions } = useAppData();
  const templateTypes = enumOptions['Events::ActivityTemplateTypes'];

  return (
    <Filters.Container>
      <Filters.Text label="Name" name="nameIContAny" />
      <Filters.Text label="Key" name="keyIContAny" />
      <Filters.Select label="Template Type" name="templateTypeIn" options={templateTypes} isMulti />
      <CreatedByMeFilter />
    </Filters.Container>
  );
}
