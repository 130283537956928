import React, { useState, useMemo } from 'react';
import {
  Tabs, Tab, Row, Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { IconTooltip, FormGroup } from '@tripledotstudios/react-core';

import { hasError, RegisteredError, SwitchSimple } from '@components/resource';
import CatalogProducts from './CatalogProducts';

import buildVariantDiff from '../services/build_variant_diff';

const WrappedRow = styled(Row)`
  margin-bottom: -2rem;
`;

export default function CatalogSectionsTabs({
  productLabels, catalogSectionsAttributes, defaultVariantSections,
}) {
  const buildSectionTabKey = (index) => `section_${index}`;
  const [tableEditable, setTableEditable] = useState(false);

  const controlVariantSectionsDiff = useMemo(
    () => buildVariantDiff(catalogSectionsAttributes, defaultVariantSections),
    [JSON.stringify(catalogSectionsAttributes), JSON.stringify(defaultVariantSections)],
  );

  const sectionTabClassName = (index) => (hasError(`catalogSectionsAttributes.${index}`) ? 'has-errors' : '');

  const sectionTabTitle = ({ name }, index) => (
    <span>
      {name}
      {controlVariantSectionsDiff[index]
      && (
        <IconTooltip.Exclamation
          text={`Has difference against control variant "${name}" section`}
          variant="warning"
        />
      )}
    </span>
  );

  return (
    <>
      <WrappedRow>
        <Col>
          <SwitchSimple
            className="float-end mb-2"
            checked={tableEditable}
            value="1"
            label="Edit configuration"
            onChange={(e) => setTableEditable(e.currentTarget.checked)}
          />
        </Col>
      </WrappedRow>
      <Tabs
        defaultActiveKey={buildSectionTabKey(0)}
        className="mb-3"
      >
        {catalogSectionsAttributes.map(({ section }, index) => (
          <Tab
            key={buildSectionTabKey(section.id)}
            eventKey={buildSectionTabKey(index)}
            tabClassName={sectionTabClassName(index)}
            title={sectionTabTitle(section, index)}
          >
            <FormGroup name={`catalogSectionsAttributes.${index}`}>
              <Row className="mb-1">
                <Col xs={6}>
                  <RegisteredError name="id" />
                </Col>
              </Row>
              <CatalogProducts
                tableEditable={tableEditable}
                productLabels={productLabels}
                productsDiff={controlVariantSectionsDiff[index]}
              />
            </FormGroup>
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
