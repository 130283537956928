import { useOptionsQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';

export default function useGameItemsOptions() {
  return useOptionsQuery({
    name: 'payments/gameItems',
    request: PaymentsRoutes.GameItems.listOptionsRequest,
    params: { deleted: false },
  });
}
