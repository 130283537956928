import { useEffect, useState } from 'react';
import { LabeledSelect } from '@components/resource';
import { useFormContext } from 'react-hook-form';
import { useFormGroup, useRouter } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';
import { ParametersForm } from '@components/dynamic_parameters';
import { EventsRoutes } from '@pages/routes';
import useEventsActivityTemplatesOptions from '@/pages/events/activity_templates/queries';

function ActivityTemplateSelect({
  label,
  name,
  sizes,
  handleChange,
  templateType,
  emptyTemplateOptionLabel,
}) {
  const { data: activityTemplatesOptions, isLoading } = useEventsActivityTemplatesOptions({
    templateType,
  });

  if (isLoading) { return null; }

  const options = [
    ...(emptyTemplateOptionLabel ? [{ label: emptyTemplateOptionLabel, value: '' }] : []),
    ...activityTemplatesOptions,
  ];

  return (
    <LabeledSelect
      label={label}
      name={name}
      options={options}
      onChange={handleChange}
      sizes={sizes || [3, 9]}
    />
  );
}

export default function DynamicParametersWithTemplate({
  emptyTemplateOptionLabel,
  activityTemplateLabel = 'Activity template',
  activityTemplateName = 'activityTemplateId',
  templateType,
  sizes = [3, 9],
}) {
  const { generateName } = useFormGroup();
  const { getValues } = useFormContext();
  const { query } = useRouter();
  const [newTemplateId, setNewTemplateId] = useState(null);
  const [template, setTemplate] = useState(null);
  const [newTemplate, setNewTemplate] = useState(null);
  const templateId = getValues(generateName(activityTemplateName));
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_OBJECTIVE];

  const handleActionChange = (val) => setNewTemplateId(val);

  useEffect(() => {
    if (templateId) {
      EventsRoutes.ActivityTemplates.editRequest({ ...query, id: templateId }).then((response) => {
        setTemplate(response.data);
      });
    } else {
      setTemplate(null);
    }
  }, []);

  useEffect(() => {
    if (newTemplateId) {
      EventsRoutes.ActivityTemplates.editRequest({ ...query, id: newTemplateId }).then((response) => {
        setNewTemplate(response.data);
      });
    }
  }, [newTemplateId]);

  return (
    <>
      <ActivityTemplateSelect
        label={activityTemplateLabel}
        name={activityTemplateName}
        sizes={sizes}
        handleChange={handleActionChange}
        templateType={templateType}
        emptyTemplateOptionLabel={emptyTemplateOptionLabel}
      />
      {template && (
        <ParametersForm
          newSourceEntity={newTemplate}
          sourceEntity={template}
          allowedAssetBundlesTypes={assetBundlesTypes}
          entityName="Activity Template"
        />
      )}
    </>
  );
}
