import { useAppData, useCurrentApplication } from '@hooks';
import useCashProductsOptions from '@/pages/payments/cash_products/queries';
import useGameProductsOptions from '@/pages/payments/game_products/queries';

export default function useProductsOptions() {
  const currentApplication = useCurrentApplication();
  const productTypes = useAppData().enums.ApplicationProductsTypes;
  const isGameProductsApplication = currentApplication.productsType === productTypes.GAME_PRODUCTS;

  return isGameProductsApplication ? useGameProductsOptions() : useCashProductsOptions();
}
